<template>
  <v-flex class="no-participant">
    <CirclePlaceholder :partnerInitials="initials" color="dark" />
    <div class="cirlce-title">
      {{ $t("visits.video.noPartner") }}
    </div>
  </v-flex>
</template>

<script>
export default {
  name: "NoParticipant",
  props: {
    initials: String,
    participantId: String,
  },
  components: {
    CirclePlaceholder: () => import("./CirclePlaceholder.vue"),
  },
};
</script>

<style lang="scss" scoped>
.no-participant {
  /*height: 100vh;*/
}

.cirlce-title {
  position: absolute;
  top: calc(50% + 3.5rem);
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 27px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.24px;
  color: $white;
  opacity: 0.92;
}
</style>
